<template>
  <div v-loading="loading" class="table-area">
    <el-row class="table-top table-heading-market-watch">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Market Watch</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="search-wrapper text-right">
            <el-autocomplete
            v-model="stockQuery.search"
            :fetch-suggestions="querySearchAsync"
            placeholder="Please enter at least 3 characters"
            style="width:600px;"
            clearable
            @select="handleSelect"
          >
              <el-select
                slot="prepend"
                v-model="stockQuery.exchange"
                placeholder="Select One"
                clearable
                filterable
                style="width: 150px"
              >
                <el-option
                  v-for="item in exchangeTypes"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>

             

              <el-button type="primary" slot="append" @click="handleSave()">Save</el-button>
              
            </el-autocomplete>
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <!-- <el-button type="primary" @click="handleCreate()">Add</el-button> -->
        </div>
      </el-col>
    </el-row>

    <div>
       <el-tabs class="marketwatch_tab" type="card" @tab-click="handleTabClick" v-model="currentTab">
        <el-tab-pane label="NSE" name="nse">
          <div style="padding-top: 60px">
            <CustomeMarketWatchTable
              ref="nse_table"
              tableKey="closePositions"
              :tableConfig="config"
              :tableData="listDataNSE"
              :tableQuery="listQuery"
              :tableActionShowVisibility="tableActionShowVisibility"
              :tableFavoriteActionVisibility="tableFavoriteActionVisibility"
              :tableActionViewVisibility="false"
              :tableActionDeleteVisibility="false"
              :tableActionEditVisibility="false"
              :tableTradeActionVisibility="tableTradeActionVisibility"
              :tableLoadingStatus.sync="loading"
              @row-click="handleRowClick"
              @pagination="handlePagination()"
              @delete="handleDelete($event)"
              @removeFavorite="removeFavorite($event)"
              @addFavorite="addFavorite($event)"
              @handleRowClick="handleRowClick($event)"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="MCX" name="mcx">
          <div style="padding-top: 60px">
            <CustomeMarketWatchTable
              tableKey="closePositions"
              :tableConfig="config"
              :tableData="listDataMCX"
              :tableQuery="listQuery"
              :tableActionShowVisibility="tableActionShowVisibility"
              :tableFavoriteActionVisibility="tableFavoriteActionVisibility"
              :tableActionViewVisibility="false"
              :tableActionDeleteVisibility="false"
              :tableActionEditVisibility="false"
              :tableTradeActionVisibility="tableTradeActionVisibility"
              :tableLoadingStatus.sync="loading"
              @row-click="handleRowClick"
              @pagination="handlePagination()"
              @delete="handleDelete($event)"
              @removeFavorite="removeFavorite($event)"
              @addFavorite="addFavorite($event)"
              @handleRowClick="handleRowClick($event)"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="OPT" name="opt">
          <div style="padding-top: 60px">
            <CustomeMarketWatchTable
              tableKey="closePositions"
              :tableConfig="config"
              :tableData="listDataOPT"
              :tableQuery="listQuery"
              :tableActionVisibility="false"
              :tableFavoriteActionVisibility="tableFavoriteActionVisibility"
              :tableActionDeleteVisibility="false"
              :tableActionViewVisibility="false"
              :tableActionEditVisibility="false"
              :tableTradeActionVisibility="tableTradeActionVisibility"
              :tableActionShowVisibility="tableActionShowVisibility"
              :tableLoadingStatus.sync="loading"
              @pagination="handlePagination()"
              @delete="handleDelete($event)"
              @edit="handleEdit($event)"
              @removeFavorite="removeFavorite($event)"
              @addFavorite="addFavorite($event)"
              @handleRowClick="handleRowClick($event)"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="CRYPTO" name="crypto">
          <div style="padding-top: 60px">
            <CustomeMarketWatchTable
              tableKey="closePositions"
              :tableConfig="config"
              :tableData="listDataCRYPTO"
              :tableQuery="listQuery"
              :tableActionVisibility="false"
              :tableActionDeleteVisibility="true"
              :tableActionViewVisibility="false"
              :tableActionEditVisibility="false"
      
              :tableLoadingStatus.sync="loading"
              @pagination="handlePagination()"
              @delete="handleDelete($event)"
              @edit="handleEdit($event)"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="FOREX" name="forex">
          <div style="padding-top: 60px">
            <CustomeMarketWatchTable
              tableKey="closePositions"
              :tableConfig="config"
              :tableData="listDataFOREX"
              :tableQuery="listQuery"
              :tableActionVisibility="false"
              :tableActionDeleteVisibility="true"
              :tableActionViewVisibility="false"
              :tableActionEditVisibility="false"
              
              :tableLoadingStatus.sync="loading"
              @pagination="handlePagination()"
              @delete="handleDelete($event)"
              @edit="handleEdit($event)"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <br />
    <br />

    <br />
    <div class="drawer-popup">

    <el-drawer
      :title="dialogType"
      :visible.sync="drawer"
      :direction="direction"
    >
      <el-card class="market-watch dialog-market-watch">
        <el-form
          :model="formData"
          :rules="formRules"
          ref="refForm"
          label-position="top"
          :status-icon="true"
        >
          <el-form-item class="add_dialog_label" label="Stock" prop="stock_id">
            <el-select v-model="formData.stock_id" @change="getType()" placeholder="Select" clearable filterable>
              <el-option
                v-for="item in listStockDataDropdownAll"
                :key="item.stock_id"
                :label="item.name"
                :value="item.stock_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Lots" prop="unit" v-if="select_type == 'MCX'">
            <el-input v-model="formData.unit" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Qty" prop="unit" v-else>
            <el-input v-model="formData.unit" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Select client" prop="user_id">
            <el-select v-model="formData.user_id" placeholder="Select" clearable filterable>
              <el-option
                v-for="item in listDataDropdownClients"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Select type" prop="type">
            <el-select v-model="formData.type" placeholder="Select">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Price"
            prop="price"
            v-if="formData.type === 'order'"
          >
            <el-input v-model="formData.price" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <br />
        <el-button
          type="primary"
          style="width: 100%"
          :disabled="loading" :loading="loading"
          @click="sendChildFormData()"
          >{{ buttonTitle }}</el-button
        >
      </el-card>
    </el-drawer>

    <el-drawer
      :title="dialogType"
      :visible.sync="drawer_new"
      :direction="direction"
    >
      <el-card class="market-watch dialog-market-watch">
        <el-form
          :model="formData"
          :rules="formRules"
          ref="refForm"
          label-position="top"
          :status-icon="true"
        >
          <el-form-item class="add_dialog_label" label="Stock" prop="stock_id">
            <el-select v-model="formData.stock_id" placeholder="Select" clearable filterable>
              <el-option
                v-for="item in listStockDataDropdownAll"
                :key="item.stock_id"
                :label="item.name"
                :value="item.stock_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Lots" prop="unit">
            <el-input v-model="formData.unit" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Select client" prop="user_id">
            <el-select v-model="formData.user_id" placeholder="Select" clearable filterable>
              <el-option
                v-for="item in listDataDropdownClients"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Select type" prop="type">
            <el-select v-model="formData.type" placeholder="Select">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Price"
            prop="price"
            v-if="formData.type === 'order'"
          >
            <el-input v-model="formData.price" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <br />
        <el-button
          type="primary"
          style="width: 40%"
          :disabled="loading" :loading="loading"
          @click="sendChildFormData('buy')"
          >Buy</el-button
        >
        <el-button
          type="primary"
          style="width: 40%"
          :disabled="loading" :loading="loading"
          @click="sendChildFormData('sell')"
          >Sell</el-button
        >
      </el-card>
    </el-drawer>
    </div>
    <AddEditDialog
      :dialog-visible.sync="dialogVisible"
      :dialog-type="dialogType"
      :form-data="addData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormData($event)"
      @childClose="handleChildClose()"
    />
  </div>
</template>

<script>
import CustomeMarketWatchTable from "@/components/Table/CustomeMarketWatchTable.vue";
import {
  fetchList,
  fetchClientList,
  store,
  addStock,
  fetchAdminAllowedStockList,
  favorite,
  getStockList
} from "@/api/market-watch";
// import { fetchList, fetchClientList } from "@/api/market-watch";
import { requiredRule, numberRule, decimalRule } from "@/rules/all-rules";
import AddEditDialog from "@/views/market-watch/components/AddEditDialog.vue";
import { _ } from "vue-underscore";
import { mapGetters } from "vuex";

export default {
  name: "ListClosePositions",
  components: {
    AddEditDialog,
    CustomeMarketWatchTable,
  },
  data() {
    return {
      testNSEData: {
        data: {
          item: [],
        },
      },
      tableActionShowVisibility :false,
      tableFavoriteActionVisibility:false,
      tableTradeActionVisibility:false,
      dialogVisible: false,
      drawer: false,
      drawer_new:false,
      direction: "rtl",
      loading: false,
      currentTab: "nse",
      type:"nse",
      select_type:"",
      exchangeTypes :['NSE','MCX','OPT', 'CRYPTO', "FOREX"],
      stockQuery: {
        search: "",
        exchange: ""
      },

      stocks: [],
      timeout:  null,
      savedata:{},
      config: [
        {
          label: "Script",
          prop: "name",
          width: "",
          className: "redFont",
        },
        {
          label: "Ask",
          prop: "ask",
          width: "110",
          className: "blueFont",
        },
        {
          label: "Bid",
          prop: "bid",
          width: "110",
          className: "blueFont",
        },
        
        {
          label: "Ltp",
          prop: "ltp",
          width: "110",
          className: "blueFont",
        },
        {
          label: "Change",
          prop: "ch",
          width: "110",
          className: "blueFont",
        },
        {
          label: "High",
          prop: "high",
          width: "110",
          className: "blueFont",
        },
        {
          label: "Low",
          prop: "low",
          width: "110",
          className: "blueFont",
        },
      ],
      addData: {
        exchange: "NSE",
      },
      listDataNSE: {
        data: {
          item: [],
        },
      },
      listDataOPT: {
        data: {
          item: [],
        },
      },
      listDataMCX: {
        data: {
          item: [],
        },
      },
      listDataCRYPTO: {
        data: {
          item: [],
        },
      },
      listDataFOREX: {
        data: {
          item: [],
        },
      },
      listDataTempCRYPTO:{},
      listDataTempFOREX:{},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
        exchange: "nse"
      },

      options: [
        {
          value: "market",
          label: "Market",
        },
        {
          value: "order",
          label: "Order",
        },
      ],
      listStockDataDropdownAll: {},
      listDataDropdownClients: {},
      formData: {
        by_market: null,
      },
      formRules: {},
      formErrors: [],
      dialogType: "",
      dialogValue: "",
      buttonTitle: "",
    };
  },
  sockets: {

    connect: function () {
      console.log('connected live 11')
    },
    
    updateStockNSE: function (data) {
      if(data && this.type == 'nse'){
        this.updateStockData('NSE', JSON.parse(data));
      }
    },

    updateStockMCX: function (data) {
      if(data && this.type == 'mcx'){
        this.updateStockData('MCX', JSON.parse(data));
      }
    },
    updateStockOPT: function (data) {
      if(data && this.type == 'opt'){
        this.updateStockData('OPT', JSON.parse(data));
      }
    },
    updateStockCRYPTO: function (data) {
      if(data && this.type == 'crypto'){
        this.updateStockData('CRYPTO', JSON.parse(data));
      }
    }

},

computed: {
    ...mapGetters(["getRoleSlug"]),
  },
  created() {
    window.addEventListener("keydown", this.keyDownHandler);
    this.getList();
    this.getClientList();
    this.getStockList();
    this.formRules.stock_id = requiredRule("stock_id");
    this.formRules.unit = numberRule("unit", ["required"]);
    this.formRules.user_id = requiredRule("user_id");
    this.formRules.type = requiredRule("type");
    this.formRules.transaction_password = requiredRule("transaction_password");
    this.formRules.price = decimalRule("price", ["required"]);
    if (this.getRoleSlug == 'admin') {
     this.tableActionShowVisibility = true;
     this.tableFavoriteActionVisibility = true;
     this.tableTradeActionVisibility = true;
    }else if (this.getRoleSlug == 'broker') {
      this.tableTradeActionVisibility = true;
    }
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  methods: {
    keyDownHandler(e) {
      // console.log(e.key);
      if (e.key == "-") {
        this.dialogType = "Sell";
        this.dialogValue = "sell";
        this.buttonTitle = "Sell";
        this.formData = {};
        this.drawer = true;
      } else if (e.key == "=" || e.key == "+") {
        this.dialogType = "Buy";
        this.dialogValue = "buy";
        this.buttonTitle = "Buy";
        this.formData = {};
        this.drawer = true;
      }
    },




    querySearchAsync(queryString, cb) {
        var stocks = this.stocks;
        if(this.stockQuery.search.length > 2 ){
          clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
            getStockList(this.stockQuery).then((response) => {
            stocks =  response.data.data.stocks;
            console.log("stockdata ", response.data)
            cb(stocks);
          });
          }, 1000);
          
        }
      },

      getType(){
        this.type = "";
        let obj = this.listStockDataDropdownAll.find(x => x.stock_id === this.formData.stock_id);
       console.log("sadsads  ",obj)
       this.select_type = obj.stock_type

        
        //cons
      },
      handleSelect(item){
        this.savedata = item;
      },
      handleRowClick(item){
       console.log("fdsfsd   ",item);
        this.dialogType = "Buy/Sell";
        this.dialogValue = "";
        this.buttonTitle = "Buy/Sell";
        this.formData = {};
        this.drawer_new = true;
        this.formData.stock_id = item.stock_id;

      },
      handleSave(){
    
        let pdata = {
          id: this.savedata.id,
          type: "add"
        }
        this.loading = true;
        favorite(pdata).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          }
        });
      },

      
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },
     handleTabClick(tab) {
          console.log(tab.index);
          if (tab.index == 0) {
            this.type = 'nse'
          } else if (tab.index == 1) {
            this.type = 'mcx'
          } else if (tab.index == 2) {
            this.type = 'opt'
          }else if (tab.index == 3) {
            this.type = 'crypto'
          }else if(tab.index == 4){
            this.type = 'forex'
          }

          this.getList();
      },
    handleSearch() {
      if(this.type == 'nse') {
            this.listDataNSE.data.item = this.listDataTemp;
            let s = this.search;
            s = s.toLowerCase();
            console.log(s);
            this.listDataNSE.data.item = _.filter(this.listDataNSE.data.item, function (d) {
              return d["name"] && d["name"].toLowerCase().includes(s);
            });
      } else if(this.type == 'mcx') {
            this.listDataTempMcx.data.item = this.listDataTempMcx;
            let s = this.search;
            s = s.toLowerCase();
            console.log(s);
            this.listDataTempMcx.data.item = _.filter(this.listDataTempMcx.data.item, function (d) {
              return d["name"] && d["name"].toLowerCase().includes(s);
            });
      } else if(this.type == 'opt') {
            this.listDataOPT.data.item = this.listDataTempOPT;
            let s = this.search;
            s = s.toLowerCase();
            console.log(s);
            this.listDataOPT.data.item = _.filter(this.listDataOPT.data.item, function (d) {
              return d["name"] && d["name"].toLowerCase().includes(s);
            });

      } else if(this.type == 'crypto') {
        this.listDataCRYPTO.data.item = this.listDataTempCRYPTO;
        let s = this.search;
        s = s.toLowerCase();
        this.listDataCRYPTO.data.item = _.filter(this.listDataCRYPTO.data.item, function (d) {
          return d["name"] && d["name"].toLowerCase().includes(s);
        });
      } else if(this.type == 'forex') {
        this.listDataFOREX.data.item = this.listDataTempFOREX;
        let s = this.search;
        s = s.toLowerCase();
        this.listDataFOREX.data.item = _.filter(this.listDataFOREX.data.item, function (d) {
          return d["name"] && d["name"].toLowerCase().includes(s);
        });
      } else {
        console.log('Oops....')
      }

    },
    getList() {
      this.loading = true;
      this.listQuery.exchange = this.type ?? 'nse';
      fetchList(this.listQuery).then((response) => {
       this.listDataTemp =  this.listDataNSE.data.item = response.data.data.nse;
       this.listDataTempOPT = this.listDataOPT.data.item = response.data.data.opt;
       this.listDataTempMcx = this.listDataMCX.data.item = response.data.data.mcx;
       this.listDataTempCRYPTO = this.listDataCRYPTO.data.item = response.data.data.crypto;
       this.listDataTempFOREX = this.listDataFOREX.data.item = response.data.data.forex;
        this.listDataDropdownAll = response.data.data.all;
        // console.log(this.listDataOPT);
        this.loading = false;
      });
    },

    updateStockData(type, data){
      let index;
      switch (type) {
        case 'NSE':
          index = this.getStockDataIndex(this.listDataNSE.data.item, data);
          if(index != -1){
    
            this.$set(this.listDataNSE.data.item, index, { ...this.listDataNSE.data.item[index], bid :data.bid, ask:data.ask, ch: data.ch, ltp: data.ltp, ltp_up:data.ltp_up, high:data.high, low:data.low});
          }
          break;

        case 'MCX':
          index = this.getStockDataIndex(this.listDataMCX.data.item, data);
          if(index != -1){
            //this.$set(this.listDataMCX.data.item, index, data);
            this.$set(this.listDataMCX.data.item, index, { ...this.listDataMCX.data.item[index], bid :data.bid, ask:data.ask, ch: data.ch, ltp: data.ltp, ltp_up:data.ltp_up, high:data.high, low:data.low});
          }
          break;

        case 'OPT':
          index = this.getStockDataIndex(this.listDataOPT.data.item, data);
          if(index != -1){
            //this.$set(this.listDataOPT.data.item, index, data);
            this.$set(this.listDataOPT.data.item, index, { ...this.listDataOPT.data.item[index], bid :data.bid, ask:data.ask, ch: data.ch, ltp: data.ltp, ltp_up:data.ltp_up, high:data.high, low:data.low});
          }
          break;
      
          case 'CRYPTO':
            index = this.getStockDataIndex(this.listDataCRYPTO.data.item, data);
            if(index != -1){
              this.$set(this.listDataCRYPTO.data.item, index, { ...this.listDataCRYPTO.data.item[index], bid :data.bid, ask:data.ask, ch: data.ch, ltp: data.ltp, ltp_up:data.ltp_up, high:data.high, low:data.low});
            }
            break;
          case 'FOREX':
            index = this.getStockDataIndex(this.listDataFOREX.data.item, data);
            if(index != -1){
              this.$set(this.listDataFOREX.data.item, index, { ...this.listDataFOREX.data.item[index], bid :data.bid, ask:data.ask, ch: data.ch, ltp: data.ltp, ltp_up:data.ltp_up, high:data.high, low:data.low});
            }
            break;
        default:
          break;
      }
    },

    getStockDataIndex(arr, data){
      return arr.findIndex(x => x.stock_id === data.stock_id);
    },


    // getList() {
    //   this.loading = true;
    //   fetchList(this.listQuery).then((response) => {
    //     this.listDataNSE.data.item = response.data.data.nse;
    //     this.listDataOPT.data.item = response.data.data.opt;
    //     this.listDataMCX.data.item = response.data.data.mcx;
    //     console.log(this.listDataNSE);
    //     console.log(this.listDataOPT);
    //     this.loading = false;
    //   });
    // },
    getStockList() {
      fetchAdminAllowedStockList().then((response) => {
        this.listStockDataDropdownAll = response.data.data.all;
      });
    },

    handleCreate() {
      this.dialogType = "create";
      this.dialogVisible = true;
    },
    handleChildFormData(data) {
      if (data.dialog_type === "create") {
        this.handleStore(data);
      }
    },
    removeFavorite(data) {
      console.log("sdfsdfdsf    "  ,data);
      this.$confirm(
        "Would you like to remove from your favourite list?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        let pdata = {
          id:data.user_stock_id,
          type: "remove"
        }
        this.loading = true;
        favorite(pdata).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          }
        });
      });
    },



    addFavorite(data) {
      console.log(data.id);
      this.$confirm(
        "Would you like to add in your favourite list?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        let pdata = {
          id:data.user_stock_id,
          type: "add"
        }
        favorite(pdata).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          }
        });
      });
    },

    handleStore(data) {
      this.loading = true;
      console.log(data);
      this.dialogVisible = false;
      addStock(data).then((response) => {
        console.log(response);
        if (response.data.success === true) {
          this.flashSuccess(response.data.message);
          this.handleChildClose();
          this.getList();
        } else {
          this.flashError(response.data.message);
          this.handleChildClose();
          this.getList();
        }
      });
    },
    handleChildClose() {
      this.dialogVisible = false;
    },
    getClientList() {
      this.loading = true;
      fetchClientList().then((response) => {
        this.listDataDropdownClients = response.data.data;
      });
    },
    sendChildFormData(r_type) {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          // check for Type MARKET / ORDER  (True / False)
          var type = this.formData.type;
          if (type === "market") {
            this.by_market = true;
          } else {
            this.by_market = false;
          }
          this.loading = true;
          // check for STOCK Type BUY / SELL
          let order_type = this.dialogValue;
          if(r_type){
            order_type = r_type;
          }

          if (this.dialogValue === "buy") {
            this.formData = Object.assign(this.formData, {
              order_type: order_type,
              by_market: this.by_market,
            });
          } else {
            this.formData = Object.assign(this.formData, {
              order_type: order_type,
              by_market: this.by_market,
            });
          }
          

          console.log(this.formData);

          store(this.formData)
            .then((response) => {
              if (response.data.success === true) {
                this.drawer = false;
                this.flashSuccess(response.data.message);
                this.getList();
                this.loading = false;
              } else {
                this.flashError(response.data.message);
                this.formErrors = this.validationErrors(response.data.message);
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
