<template>
  <div class="menu_wrapper_container box-shadow trading-container" v-loading="loading">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
<el-row>
  <el-col :span="12">
    <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
       <el-radio-group v-model="formData.exchange">
        <el-radio-button label="NSE"></el-radio-button>
        <el-radio-button label="MCX"></el-radio-button>
        <el-radio-button label="OPT"></el-radio-button>
       </el-radio-group>
       <el-form-item :error="formErrors.name"
                  label="Name"
                  prop="name"
                  :label-width="formLabelWidth">
        <el-input v-model="formData.name"></el-input>
       </el-form-item>
      </el-form>
  
  </el-col>
</el-row>
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog()">Cancel</el-button>
        <el-button type="info"  @click="sendChildFormData()">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { requiredRule } from "@/rules/all-rules";

export default {
  name: "AddEditDialog",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      formLabelWidth: "120px",
    };
  },
  computed: {
    dialogTitle() {
      // console.log("fdsf     ",this.dialogType)
      return this.dialogType === "create"
        ? "Add Stock"
        : "";
    },
  },
   created() {
     this.formRules.name = requiredRule("name");
  },
  methods: {
    handleCloseDialog() {
      this.$emit("childClose");
    },
    sendChildFormData() {
      this.loading = true;
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          // WHEN EDIT MERGE NEW PARAMETERS
          if (this.dialogType === "create") {
            this.formData = Object.assign(this.formData, {
              dialog_type: this.dialogType,
            });
          }
          this.$emit("getChildFormData", this.formData);
          this.loading = false;
        }
      });
    },
  },
};
</script>
